<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import { FilterMatchMode } from 'primevue/api'
import { useRouter } from 'vue-router'
import axios from 'axios'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const router = useRouter()

const emits = defineEmits(['chatCallback'])

const props = defineProps({
    company_id: { type: Number, required: true }
})

const company = ref({warnings: [], labels: [], change_deal: null, change_comment: "", new_comment: "", new_deal: null, deal: null})
const statuses = ref([])

const edit_company = ref({
    name: false,
    email: false,
    phone: false,
    street: false,
    postal_code: false,
    city: false,
    labels: false,
    broker: false,
    deal: false,
    legal_form: false,
    employee_count: false,
    aktuelle_pk: false,
    branche: false
})

const types = ref([
    {name: 'Kunde', value: 10},
    {name: 'Broker', value: 28},
    {name: 'Interessent', value: 11},
    {name: 'Partner', value: 12},
    {name: 'Keine Kategorie', value: 13}
])

const languages = ref([
    {
        "id":34,
        "label":"de"
    },
    {
        "id":33,
        "label":"fr"
    },
    {
        "id":35,
        "label":"it"
    },
    {
        "id":36,
        "label":"es"
    }
])

const salutations = ref([
    {
        "id":29,
        "label":"Herr"
    },
    {
        "id":30,
        "label":"Frau"
    },
    {
        "id":31,
        "label":"Monsieur"
    },
    {
        "id":32,
        "label":"Madame"
    }
])

const get_salutation = (id) => {
    return salutations.value.find(salutation => salutation.id == id).label
}

const get_language = (id) => {
    return languages.value.find(language => language.id == id).label
}

onMounted( async () => {
    console.log("hello company component")
    get_company()
    get_linked_contacts()
    get_crm_statuses()
    get_company_files()
    get_company_notes()
})

const get_company = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/prosperita/get-company", {"company_id": props.company_id})
    .then (response => {
        console.log("company", response.data)
        company.value = response.data
        company.value.change_deal = null
        company.value.change_comment = ""
        company.value.new_deal = null
        company.value.new_comment = ""
        loader.value = false
    })
}

const get_crm_statuses = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-crm-statuses')
    .then(response => {
        statuses.value = response.data
        console.log("statuses", response.data)
    })
}

const save_company = (field) => {
    loader.value = true
    console.log("save company", field, company.value[field])
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/save-company', {"company_id": props.company_id, "field": field, "value": company.value[field]})
    .then(response => {
        console.log("company updated", response.data)
        get_company()
        loader.value = false
        edit_company.value[field] = false
        toast.add({severity: 'success', summary: 'Erfolg', detail: 'Firma erfolgreich aktualisiert', life: 3000})
    })
}

const org_files = ref({})
const org_files_total = ref(0)
const selected_category = ref("")
const selected_file_category = ref("")
const files_explorer = ref([])
const get_company_files = async () => {
    await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-organization-files', {"org_id": props.company_id})
    .then(response => {
        console.log("files", response.data)
        org_files.value = response.data
        org_files_total.value = response.data.counts.total
        if (response.data.counts.total > 0) {
            selected_file_category.value = Object.keys(org_files.value['files'])[0]
            files_explorer.value = org_files.value['files'][selected_category.value]
        }
    })
}

const org_notes = ref([])
const get_company_notes = async () => {
    await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-organization-updates', {"org_id": props.company_id})
    .then(response => {
        org_notes.value = response.data.data
        console.log("notes", response.data)
    })
}

const linked_contacts = ref([])
const get_linked_contacts = (company_id) => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-linked-contacts', {"company_id": props.company_id})
    .then(response => {
        console.log(response.data)
        linked_contacts.value = response.data
        loader.value = false
    })
}

const contact = ref({})
const edit_contact_dialog = ref(false)
const edit_contact = (data) => {
    contact.value = data
    console.log("edit contact", contact.value)
    edit_contact_dialog.value = true
}

const start_new_deal = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/start-new-deal', {"company_id": props.company_id, "company_name": company.value.name, "deal": company.value.new_deal, "comment": company.value.new_comment})
    .then(response => {
        if (response.data.message == 'error_exists') {
            loader.value = false
            toast.add({severity: 'error', summary: 'Fehler', detail: 'Es existiert bereits ein Vertriebsprozess für diese Firma', life: 3000})
        }
        if (response.data.message == 'success') {
            console.log("deal started", response.data)
            loader.value = false
            toast.add({severity: 'success', summary: 'Erfolg', detail: 'Vertriebsprozess erfolgreich gestartet', life: 3000})
            get_company()
        }
    })
}

const create_contact = () => {
    contact.value.org_id = company.value.id
    contact.value.id = null
    contact.value.name = ""
    contact.value.first_name = ""
    contact.value.last_name = ""
    contact.value.email = ""
    contact.value.phone = ""
    contact.value.anrede = ""
    contact.value.sprache = ""
    contact.value.position = ""
    console.log("new contact", contact.value)
    edit_contact_dialog.value = true
}

const save_contact = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/save-contact', contact.value)
    .then(response => {
        console.log("contact saved", response.data)
        get_linked_contacts()
        loader.value = false
        edit_contact_dialog.value = false
        toast.add({severity: 'success', summary: 'Erfolg', detail: 'Kontakt erfolgreich aktualisiert', life: 3000})
    })
}

const create_new_note_dialog = ref(false)
const new_note = ref("")
const create_new_note = () => {
    create_new_note_dialog.value = true
}

const save_note = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/save-note', {"org_id": props.company_id, "note": new_note.value})
    .then(response => {
        console.log("note saved", response.data)
        get_company_notes()
        loader.value = false
        create_new_note_dialog.value = false
        toast.add({severity: 'success', summary: 'Erfolg', detail: 'Notiz erfolgreich erstellt', life: 3000})
    })
}

const delete_contact = (id, name) => {
    confirm.require({
        message: 'Möchten Sie diesen Kontakt wirklich löschen?',
        header: 'Kontakt löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Kontakt löschen',
        rejectLabel: 'Nein',
        acceptClass: 'p-button-danger',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/delete-contact', {"id": id, "org_id": props.company_id, "name": name})
            .then(response => {
                console.log("contact deleted", response.data)
                get_linked_contacts()
                loader.value = false
                toast.add({severity: 'success', summary: 'Erfolg', detail: 'Kontakt erfolgreich gelöscht', life: 3000})
            })
        }
    })
}

const delete_deal = (id) => {
    confirm.require({
        message: 'Möchten Sie diesen Vertriebsprozess wirklich löschen?',
        header: 'Vertriebsprozess löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Vertriebsprozess löschen',
        rejectLabel: 'Nein',
        acceptClass: 'p-button-danger',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/delete-deal', {"id": id})
            .then(response => {
                console.log("deal deleted", response.data)
                get_company()
                loader.value = false
                toast.add({severity: 'success', summary: 'Erfolg', detail: 'Vertriebsprozess erfolgreich gelöscht', life: 3000})
            })
        }
    })
}

const delete_company = (id) => {
    confirm.require({
        message: 'Möchten Sie diese Firma wirklich löschen?',
        header: 'Firma löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Firma löschen',
        rejectLabel: 'Nein',
        acceptClass: 'p-button-danger',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/delete-company', {"id": props.company_id})
            .then(response => {
                console.log("company deleted", response.data)
                loader.value = false
                toast.add({severity: 'success', summary: 'Erfolg', detail: 'Firma erfolgreich gelöscht', life: 3000})
                location.reload()
            })
        }
    })
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + " " + value.substring(11,16)
}

const download_file = (file_id, file_name) => {
    window.open(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/pipedrive-download-file?file_id=' + file_id + '&file_name='+file_name, '_blank')
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <div v-if="!loader">
        <div class="flex align-items-center justify-content-end">
            <Button icon="pi pi-plus" class="p-button-rounded mr-2" label="Notiz hinzufügen" @click="create_new_note()" />
            <Button icon="pi pi-plus" class="p-button-rounded mr-2" label="Dateien hinzufügen" @click="new_note()" />
        </div>
        <TabView class="w-full mt-4">
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-building" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Firma</span>
                        <Badge v-if="company.warnings.length > 0" :value="company.warnings.length" class="ml-2 bg-red-600 text-white" />
                    </div>
                </template>
                <div class="col-12 grid">
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Namen
                    </div>
                    <div class="col-8 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_company.name" v-model="company.name" class="w-full" />
                        <span v-else class="mr-2" @click="edit_company.name = true">{{company.name}}</span>
                        <i v-if="!edit_company.name" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_company.name = true" />
                        <i v-if="edit_company.name" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_company('name')" />
                        <i v-if="edit_company.name" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_company.name = false" />
                    </div>
                    <div class="col-1 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <i class="pi pi-trash cursor-pointer text-red-600 hover:text-black-alpha-90 mt-2" @click="delete_company()" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap" :class="!company.street ? 'text-red-600' : ''">
                        Strasse
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_company.street" v-model="company.street" class="w-full" />
                        <span v-if="!edit_company.street" @click="edit_company.street = true" class="mr-2">{{company.street}}</span>
                        <i v-if="!edit_company.street" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_company.street = true" />
                        <i v-if="edit_company.street" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_company('street')" />
                        <i v-if="edit_company.street" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_company.street = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        PLZ
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_company.postal_code" v-model="company.postal_code" class="w-full" />
                        <span v-if="!edit_company.postal_code" @click="edit_company.postal_code = true" class="mr-2">{{company.postal_code}}</span>
                        <i v-if="!edit_company.postal_code" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_company.postal_code = true" />
                        <i v-if="edit_company.postal_code" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_company('postal_code')" />
                        <i v-if="edit_company.postal_code" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_company.postal_code = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Ort
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_company.city" v-model="company.city" class="w-full" />
                        <span v-if="!edit_company.city" @click="edit_company.city = true" class="mr-2">{{company.city}}</span>
                        <i v-if="!edit_company.city" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_company.city = true" />
                        <i v-if="edit_company.city" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_company('city')" />
                        <i v-if="edit_company.city" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_company.city = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Rechtsform
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_company.legal_form" v-model="company.legal_form" class="w-full" />
                        <span v-if="!edit_company.legal_form" @click="edit_company.legal_form" class="mr-2">{{company.legal_form}}</span>
                        <i v-if="!edit_company.legal_form" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_company.legal_form = true" />
                        <i v-if="edit_company.legal_form" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_company('legal_form')" />
                        <i v-if="edit_company.legal_form" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_company.legal_form = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Anzahl Mitarbeiter
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_company.employee_count" v-model="company.employee_count" class="w-full" />
                        <span v-if="!edit_company.employee_count" @click="edit_company.employee_count = true" class="mr-2">{{company.employee_count}}</span>
                        <i v-if="!edit_company.employee_count" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_company.employee_count = true" />
                        <i v-if="edit_company.employee_count" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_company('employee_count')" />
                        <i v-if="edit_company.employee_count" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_company.employee_count = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Aktuelle PK
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_company.aktuelle_pk" v-model="company.aktuelle_pk" class="w-full" />
                        <span v-if="!edit_company.aktuelle_pk" @click="edit_company.aktuelle_pk" class="mr-2">{{company.aktuelle_pk}}</span>
                        <i v-if="!edit_company.aktuelle_pk" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_company.aktuelle_pk = true" />
                        <i v-if="edit_company.aktuelle_pk" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_company('aktuelle_pk')" />
                        <i v-if="edit_company.aktuelle_pk" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_company.aktuelle_pk = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Branche
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_company.branche" v-model="company.branche" class="w-full" />
                        <span v-if="!edit_company.branche" @click="edit_company.branche" class="mr-2">{{company.branche}}</span>
                        <i v-if="!edit_company.branche" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_company.branche = true" />
                        <i v-if="edit_company.branche" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_company('branche')" />
                        <i v-if="edit_company.branche" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_company.branche = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Firmentyp
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span v-if="!edit_company.labels">
                            <template v-for="label in company.labels" :key="label">
                                <Chip v-if="label == 10" class="mr-2 mb-1 bg-blue-700 text-white" label="Kunde" @click="edit_company.labels = true" />
                                <Chip v-if="label == 28" class="mr-2 mb-1 bg-orange-600 text-white" label="Broker" @click="edit_company.labels = true" />
                                <Chip v-if="label == 11" class="mr-2 mb-1 bg-red-700 text-white" label="Interessent" @click="edit_company.labels = true" />
                                <Chip v-if="label == 12" class="mr-2 mb-1 bg-purple-700 text-white" label="Partner" @click="edit_company.labels = true" />
                                <Chip v-if="label == 13" class="mr-2 mb-1 bg-gray-700 text-white" label="Keine Kategorie" @click="edit_company.labels = true" />
                            </template>
                        </span>
                        <i v-if="!edit_company.labels" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_company.labels = true" />
                        <MultiSelect v-if="edit_company.labels" v-model="company.labels" :options="types" optionLabel="name" optionValue="value" class="w-full" />
                        <i v-if="edit_company.labels" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_company('labels')" />
                        <i v-if="edit_company.labels" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_company.labels = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Broker
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Chip v-if="company.broker" icon="pi pi-lock" class="mr-2 mb-1 bg-red-700 text-white" :label="company.broker" />
                        <i class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" />
                    </div>
                    <div v-if="!company.deal" class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Vertriebsprozess starten
                    </div>
                    <div v-if="!company.deal" class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Dropdown v-model="company.new_deal" :options="statuses" showClear optionLabel="name" optionValue="id" class="mr-2" />
                    </div>
                    <div v-if="company.new_deal" class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Editor v-model="company.new_comment" class="w-full" editorStyle="height: 150px">
                            <template v-slot:toolbar>
                                <span class="ql-formats">
                                    <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                    <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                    <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                </span>
                            </template>
                        </Editor>
                    </div>
                    <div v-if="company.new_deal" class="col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        <Button :disabled="company.new_comment ? false : true" label="Speichern" @click="start_new_deal()" icon="pi pi-save" class="w-auto mr-2 bg-green-700 text-white border-green-600" />
                    </div>
                    <div v-if="!company.new_comment && company.new_deal" class="col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        <small class="text-red-700">Achtung: Bitte alle Pflichtfelder ausfüllen</small>
                    </div>
                    <div v-if="company.deal" class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Vertriebsprozess
                    </div>
                    <div v-if="company.deal" class="col-8 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Chip icon="pi pi-folder" v-if="!company.change_deal" :label="company.deal" class="mr-2 bg-green-200 text-black-alpha-90 cursor-pointer mt-2" @click="router.push('/dashboard/Vertrieb?deal_id='+company.deal)" />
                        <i v-if="!company.change_deal" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="company.change_deal = company.deal" />
                        <Dropdown v-if="company.change_deal" v-model="company.change_deal" :options="statuses" showClear optionLabel="name" optionValue="id" class="mr-2" />
                    </div>
                    <div v-if="company.deal" class="col-1 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <i class="pi pi-trash cursor-pointer text-red-600 hover:text-black-alpha-90 mt-2" @click="delete_deal(company.deal_id)" />
                    </div>
                    <div v-if="company.change_deal" class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Bemerkungen
                    </div>
                    <div v-if="company.change_deal" class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Editor v-model="company.change_comment" class="w-full" editorStyle="height: 150px">
                            <template v-slot:toolbar>
                                <span class="ql-formats">
                                    <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                    <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                    <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                </span>
                            </template>
                        </Editor>
                    </div>
                    <div v-if="company.change_deal" class="col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        <Button :disabled="company.change_comment ? false : true" label="Speichern" icon="pi pi-save" class="w-auto mr-2 bg-green-700 text-white border-green-600" />
                    </div>
                    <div v-if="!company.change_comment && company.change_deal" class="col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        <small class="text-red-700">Achtung: Bitte alle Pflichtfelder ausfüllen</small>
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-users" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Kontakte</span>
                        <Badge :value="linked_contacts.length" class="ml-2 bg-gray-100 text-black-alpha-90" />
                    </div>
                </template>
                <DataTable :value="linked_contacts" responsiveLayout="scroll" :rowHover="true">
                    <Column field="name" header="Name">
                        <template #body="slotProps">
                            <div class="flex align-items-center">
                                <Avatar icon="pi pi-user" shape="circle" class="mr-2 bg-primary text-white" />
                                <Tag v-if="slotProps.data.anrede" class="bg-gray-100 text-gray-500 mr-2" :value="get_salutation(slotProps.data.anrede)" v />
                                <span class="font-bold mr-2">{{slotProps.data.name}}</span>
                                <Tag v-if="slotProps.data.sprache" :value="get_language(slotProps.data.sprache)" class="bg-gray-100 text-gray-500" />
                            </div>
                        </template>
                    </Column>
                    <Column field="email" header="E-Mail">
                        <template #body="slotProps">
                            <div class="flex align-items-center">
                                <span class="mr-2">{{slotProps.data.email}}</span>
                                <i v-if="slotProps.data.email" class="pi pi-envelope mr-2 pointer-cursor text-primary" @click="open_url('mailto:'+slotProps.data.email)" />
                            </div>
                        </template>
                    </Column>
                    <Column field="phone" header="Telefon">
                        <template #body="slotProps">
                            <div class="flex align-items-center">
                                <span class="mr-2">{{slotProps.data.phone}}</span>
                                <i v-if="slotProps.data.phone" class="pi pi-phone mr-2 pointer-cursor text-primary" @click="open_url('tel:'+slotProps.data.phone)" />
                            </div>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button rounded icon="pi pi-pencil" class="p-button p-button-text" @click="edit_contact(slotProps.data)" />
                            <Button rounded icon="pi pi-trash" class="p-button p-button-text p-button-danger" @click="delete_contact(slotProps.data.id, slotProps.data.name)" />
                        </template>
                        <template #header>
                            <Button rounded icon="pi pi-plus" class="p-button" @click="create_contact()" />
                        </template>
                    </Column>
                </DataTable>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-history" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Verlauf</span>
                        <Badge :value="String(org_notes.length)" class="ml-2 bg-gray-100 text-black-alpha-90" />
                    </div>
                </template>
                <div>
                    <div v-for="note in org_notes" :key="note.id" rounded class="overflow-hidden shadow-3 border-200 mt-3">
                        <div v-if="note.object == 'note'" class="grid">
                            <div class="col-12 flex align-items-center border-blue-100 border-solid border-bottom-1 bg-blue-50 p-3 mt-2 ml-2">
                                <Avatar icon="pi pi-pen-to-square" shape="circle" class="bg-primary text-white mr-2" />
                                <span class="font-bold mr-2">Bemerkung / Notiz</span>
                                <span class="text-gray-500 mr-2">@ {{format_swiss_date_time(note.data.add_time)}}</span>
                            </div>
                            <div class="col-12 p-5">
                                <span v-html="note.data.content"></span>
                            </div>
                        </div>
                        <div v-if="note.object == 'file'" class="grid">
                            <div class="col-12 flex align-items-center border-blue-100 border-solid border-bottom-1 bg-blue-50 p-3 mt-2 ml-2">
                                <Avatar icon="pi pi-file" shape="circle" class="bg-primary text-white mr-2" />
                                <span class="font-bold mr-2">Datei hinzugefügt</span>
                                <span class="text-gray-500 mr-2">@ {{format_swiss_date_time(note.data.add_time)}}</span>
                            </div>
                            <div class="col-12 p-5">
                                <Chip :label="note.data.description" class="bg-black-alpha-90 text-white mr-2" />
                                <span class="font-bold mr-2 cursor-pointer" @click="download_file(note.data.id, note.data.file_name)">{{ note.data.name }}</span>
                            </div>
                        </div>
                        <div v-if="note.object == 'dealChange'" class="grid">
                            <div class="col-12 flex align-items-center border-blue-100 border-solid border-bottom-1 bg-blue-50 p-3 mt-2 ml-2">
                                <Avatar icon="pi pi-play" shape="circle" class="bg-primary text-white mr-2" />
                                <span class="font-bold mr-2">Verkaufsprozess gestartet</span>
                                <span class="text-gray-500 mr-2">@ {{format_swiss_date_time(note.data.log_time)}}</span>
                            </div>
                            <div class="col-12 p-5">
                                <span class="">Verkaufsprozess gestartet</span>
                            </div>
                        </div>
                        <div v-if="note.object == 'organizationChange'" class="grid">
                            <div class="col-12 flex align-items-center border-blue-100 border-solid border-bottom-1 bg-blue-50 p-3 mt-2 ml-2">
                                <Avatar icon="pi pi-building" shape="circle" class="bg-primary text-white mr-2" />
                                <span class="font-bold mr-2">Stammdaten</span>
                                <span class="text-gray-500 mr-2">@ {{format_swiss_date_time(note.data.log_time)}}</span>
                            </div>
                            <div class="col-12 p-5">
                                <span class="">Firma erstellt/geändert</span>
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-file" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Dokumente</span>
                        <Badge :value="String(org_files_total)" class="ml-2 bg-gray-100 text-black-alpha-90" />
                    </div>
                </template>
                <div>
                    <Chip v-for="org_file, key in org_files['files']" :key="key" class="bg-black-alpha-90 text-white mr-2 p-2 hover:bg-primary cursor-pointer" :class="selected_category == key ? 'bg-primary' : 'bg-black-alpha-90'" @click="files_explorer = org_file, selected_category = key">
                        <i class="pi pi-folder" style="margin-right: 0.5rem"></i>
                        <span>{{ key }}</span>
                        <Badge :value="String(org_file.length)" class="ml-2 bg-gray-100 text-black-alpha-90" />
                    </Chip>
                </div>
                <div>
                    <div v-for="file_explorer in files_explorer" :key="file_explorer" rounded class="overflow-hidden shadow-3 border-200 mt-3">
                        <div class="col-12 flex align-items-center border-blue-100 border-solid border-bottom-1 bg-blue-50 p-3 mt-2 ml-2">
                            <Avatar icon="pi pi-file" shape="circle" class="bg-primary text-white mr-2" />
                            <span class="font-bold mr-2">{{ file_explorer.name }}</span>
                            <span class="text-gray-500 mr-2">@ {{ format_swiss_date_time(file_explorer.add_time) }}</span>
                        </div>
                        <div class="col-12 p-5">
                            <span class="font-bold text-primary mr-2 cursor-pointer" @click="download_file(file_explorer.id, file_explorer.file_name)">{{ file_explorer.file_name }}</span>
                            <Chip :label="file_explorer.file_type" class="mr-2" />
                        </div>
                    </div>
                </div>
            </TabPanel>
        </TabView>
    </div>

    <Dialog v-model:visible="edit_contact_dialog" :style="{width: '70%'}" header="Kontaktdaten" :modal="true" class="p-fluid z-5">
        <div class="grid">
            <div class="col-6 mt-5">
                <span class="p-float-label">
                    <InputText id="first_name" v-model="contact.first_name" />
                    <label for="first_name">Vorname</label>
                </span>
            </div>
            <div class="col-6 mt-5">
                <span class="p-float-label">
                    <InputText id="last_name" v-model="contact.last_name" />
                    <label for="last_name">Nachname</label>
                </span>
            </div> 
            <div class="col-6 mt-5">
                <span class="p-float-label">
                    <InputText id="email" v-model="contact.email" />
                    <label for="email">E-Mail</label>
                </span>
            </div>
            <div class="col-6 mt-5">
                <span class="p-float-label">
                    <InputText id="phone" v-model="contact.phone" />
                    <label for="phone">Telefon</label>
                </span>
            </div>
            <div class="col-6 mt-5">
                <span class="p-float-label">
                    <Dropdown v-model="contact.anrede" :options="salutations" optionLabel="label" optionValue="id" class="w-full" />
                    <label for="position">Anrede</label>
                </span>
            </div>
            <div class="col-6 mt-5">
                <span class="p-float-label">
                    <Dropdown v-model="contact.sprache" :options="languages" optionLabel="label" optionValue="id" class="w-full" />
                    <label for="sprache">Sprache</label>
                </span>
            </div>
            <div class="col-6 mt-5">
                <span class="p-float-label">
                    <InputText id="position" v-model="contact.position" />
                    <label for="position">Position</label>
                </span>
            </div>
            <div class="col-12 mt-4">
                <Button label="Speichern" icon="pi pi-check" class="mr-2 w-auto" @click="save_contact()" />
                <Button label="Abbrechen" icon="pi pi-times" class="p-button-danger w-auto" @click="edit_contact_dialog = false" />
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="create_new_note_dialog" :style="{width: '50%'}" :header="'Notiz hinzufügen:  '+company.name" :modal="true" class="p-fluid z-5">
        <div class="grid">
            <div class="col-12">
                <Editor v-model="new_note" editorStyle="height: 320px">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                            <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                            <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                            <button v-tooltip.bottom="'Strike'" class="ql-list"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="col-12">
                <Button label="Speichern" icon="pi pi-check" class="mr-2 w-auto" @click="save_note()" />
                <Button label="Abbrechen" icon="pi pi-times" class="p-button-danger w-auto" @click="create_new_note_dialog = false" />
            </div>
        </div>
    </Dialog>
</template>