<script setup>
import { ref, onMounted, useSSRContext, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Toast from 'primevue/toast'
import { useRouter } from 'vue-router'
import Company from './sub_components/Prosperita-Company.vue'

const router = useRouter()
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const deals = ref([])
const users = ref([])
const statuses = ref([])
const company_id = ref(null)

const company_slider = ref(false)
const company_slider_position = ref('right')

const filter_deals = ref({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    deal: {value: "", matchMode: FilterMatchMode.EQUALS}
})

onMounted( async () => {
    get_deals()
    get_users()
})

const get_deals = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-deals')
    .then(response => {
        console.log("deals", response.data)
        deals.value = response.data.data
        statuses.value = response.data.statuses
        if (router.currentRoute.value.query.deal_id) {
            let deal_id = router.currentRoute.value.query.deal_id
            let status_name = statuses.value.find(status => status.id == deal_id).name
            filter_deals.value.status.value = status_name
        }
        loader.value = false
    })
}

const get_users = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-users')
    .then(response => {
        console.log(response.data)
        users.value = response.data
        loader.value = false
    })
}

const filter_status = (status) => {
    filter_deals.value.deal.value = status
    console.log(filter_deals.value)
}

const process_deal = (event) => {
    console.log(event.data)
    company_id.value = event.data.id
    company_slider.value = true
}

const get_user_name = (email) => {
    for (let key in users.value){
        if (users.value[key].email == email) {
            return users.value[key].name
        }
    }
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + " " + value.substring(11,16)
}

</script>
<style>
.tab-primrary {
    background-color: #3751ff;
    color: #FFFFFF;
}
.tab-inactive {
    background-color: #0d2f3b;
    color: #FFFFFF;
}
.tab-hidden {
    display: none;
}
</style>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <ConfirmDialog />
    <Toolbar>
        <template #start>
            <Button v-for="status in statuses" :key="status" rounded :label="status.name" @click="filter_status(status.name)" class="mr-2 bg-black-alpha-90 border-black-alpha-70 hover:bg-primary hover:border-primary white-space-nowrap" :class="filter_deals.deal.value == status.name ? 'bg-primary border-primary' : ''" :badge="String(status.total)" badgeSeverity="contrast" />
        </template>
    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText v-model="filter_deals['global'].value" placeholder="Suche" class="w-full" @update:modelValue="filter_status('')" />
            </span>
            <Button label="Aktualisieren" icon="pi pi-refresh" @click="get_deals()" class="w-auto mr-2" v-tooltip.right="'Aktualisieren'" />
        </template>
    </Toolbar>
    <DataTable class="mt-3" v-model:filters="filter_deals" @row-click="process_deal" :value="deals" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,400,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column field="add_time" header="Startdatum" sortable>
            <template #body="slotProps">
                {{ format_swiss_date(slotProps.data.deal_add_time) }}
            </template>
        </Column>
        <Column field="name" header="Firmenname">
            <template #body="slotProps">
                <span class="font-bold">{{slotProps.data.name}}</span>
            </template>
        </Column>
        <Column field="rep" header="Bearbeiter">
            <template #body="slotProps">
                <Chip :label="get_user_name(slotProps.data.rep)" class="bg-black-alpha-90 text-white" />
            </template>
        </Column>
        <Column field="update_time" header="Aktualisiert" sortable>
            <template #body="slotProps">
                {{ format_swiss_date_time(slotProps.data.deal_add_time) }}
            </template>
        </Column>
        <Column header="Resourcen">
            <template #body="slotProps">
                <Chip class="bg-primary text-white mr-2" icon="pi pi-user" :label="String(slotProps.data.contacts)" v-tooltip.bottom="'Kontakte'" />
                <Chip class="bg-primary text-white mr-2" icon="pi pi-file-edit" :label="String(slotProps.data.notes)" v-tooltip.bottom="'Notizen'" />
                <Chip class="bg-primary text-white mr-2" icon="pi pi-paperclip" :label="String(slotProps.data.files)" v-tooltip.bottom="'Dateien'" />
                <Chip hidden class="bg-red-700 text-white mr-2" icon="pi pi-bell" :label="String(0)" v-tooltip.bottom="'Kundenaufgaben'" />
            </template>
        </Column>
        <Column field="status" header="Status">
            <template #body="slotProps">
                <Chip :label="slotProps.data.deal" class="bg-black-alpha-90 text-white font-bold cursor-pointer" />
            </template>
        </Column>
    </DataTable>
    <!--------------------------------------------------------------------------->
    <!-------------------------------- Slider ---------------------------------->
    <!--------------------------------------------------------------------------->
    <Sidebar v-model:visible.sync="company_slider" :position="company_slider_position" style="width: 50%">
            <Company v-if="company_id" :company_id="company_id" />
    </Sidebar>
</template>