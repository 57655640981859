<script setup>
import { ref, onMounted, useSSRContext, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Toast from 'primevue/toast'
import { useRouter } from 'vue-router'

const router = useRouter()
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const filter_emails = ref({global: { value: null, matchMode: FilterMatchMode.CONTAINS }})

const email = ref({})
const save_email = ref({})
const emails = ref([])
const users = ref([])
const organization = ref(null)
const organizations = ref([])
const filtered_organizations = ref([])
const new_contact = ref(false)
const statuses = ref([])
const status = ref(null)
const sales_process = ref(false)
const sales_process_id = ref(null)
const sales_process_comment = ref(null)

const view_email_slider = ref(false)
const save_email_slider = ref(false)

const deleted_mode = ref(false)

const document_classifications = ref([])

const company_types = ref([
    {name: 'Kunde', value: 10},
    {name: 'Broker', value: 28},
    {name: 'Interessent', value: 11},
    {name: 'Partner', value: 12},
    {name: 'Keine Kategorie', value: 13}
])

onMounted( async () => {
    get_emails()
    get_users()
    get_classifications()
    get_crm_statuses()
    get_companies()
})


const get_inbox = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-inbox')
    .then(response => {
        console.log(response.data)
        emails.value = response.data
        loader.value = false
        deleted_mode.value = false
        if (router.currentRoute.value.query.email) {
            let workflow_id = router.currentRoute.value.query.email
            console.log("workflow_id", workflow_id)
            let email_data = emails.value.find(email => email.workflow_id === workflow_id)
            if (email_data) {
                process_email(email_data)
            }
        }
    })
}

const get_classifications = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-classifications')
    .then(response => {
        console.log(response.data)
        document_classifications.value = response.data
        loader.value = false
    })
}

const update_classifications = () => {
    confirm.require({
        message: 'Möchten Sie diese Klassifikationen wirklich speichern?',
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        acceptClass: 'p-button-danger',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/update-classifications', document_classifications.value)
            .then(response => {
                console.log(response.data)
                get_classifications()
                loader.value = false
                toast.add({severity: 'success', summary: 'Klassifikationen wurden erfolgreich gespeichert', life: 3000})
            })
        }
    })
}

const get_deleted_emails = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-deleted-emails')
    .then(response => {
        console.log(response.data)
        emails.value = response.data
        deleted_mode.value = true
        loader.value = false
    })
}

const get_companies = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-companies')
    .then(response => {
        organizations.value = response.data.data
        loader.value = false
    })
}

const get_crm_statuses = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-crm-statuses')
    .then(response => {
        statuses.value = response.data
        console.log("statuses", statuses.value)
        loader.value = false
    })
}

const get_emails = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-emails')
    .then(response => {
        get_inbox()
        loader.value = false
    })
}

const get_users = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-users')
    .then(response => {
        console.log(response.data)
        users.value = response.data
        loader.value = false
    })
}

const delete_email = (workflow_id) => {
    confirm.require({
        message: 'Möchten Sie diese E-Mail wirklich löschen?',
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        acceptClass: 'p-button-danger',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/delete-email', {workflow_id: workflow_id})
            .then(response => {
                get_emails()
                toast.add({severity: 'success', summary: 'E-Mail wurde erfolgreich in den Papierkorb verschoben', life: 3000})
                loader.value = false
            })
        }
    })
}

const restore_email = (workflow_id) => {
    confirm.require({
        message: 'Möchten Sie diese E-Mail wirklich wiederherstellen?',
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        acceptClass: 'p-button-danger',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/restore-email', {workflow_id: workflow_id})
            .then(response => {
                get_emails()
                toast.add({severity: 'success', summary: 'E-Mail wurde erfolgreich wiederhergestellt', life: 3000})
                loader.value = false
                deleted_mode.value = false
            })
        }
    })
}

const edit_email = (event) => {
    if (emails.value[event.index].owner != event.newData.owner) {
        loader.value = true
        console.log("event", event)
        emails.value[event.index].owner = event.newData.owner
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/edit-email', event.data)
        .then(response => {
            toast.add({severity: 'success', summary: 'E-Mail Bearbeiter wurde erfolgreich angepasst', life: 3000})
            get_emails()
            loader.value = false
            if (event.newData.owner) {
                confirm.require({
                    message: 'Möchten Sie eine Aufgabe erstellen?',
                    header: 'Aufgabe erstellen',
                    icon: 'pi pi-pi-check-square',
                    acceptLabel: 'Ja',
                    rejectLabel: 'Nein',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        let payload = {
                            name: "Email: " + event.data.email_subject,
                            due_date: null,
                            description: event.data.email_body_text,
                            user: event.newData.owner,
                            company: null,
                            type: 'E-Mail',
                            checklist: null,
                            email_id: event.data.workflow_id
                        }
                        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/create-task', payload)
                        .then(response => {
                            get_emails()
                            toast.add({severity: 'success', summary: 'Aufgabe wurde erfolgreich erstellt', life: 3000})
                        })
                    }
                })
            }
        })
    }
}

const view_email = (data) => {
    email.value = data
    email.value.formatted_dc = format_swiss_date_time(data.dc)
    view_email_slider.value = true
}

const process_email = (data) => {
    loader.value = true
    save_email.value = data
    if (!save_email.value.tasks) {
        save_email.value.tasks = []
    }
    save_email_slider.value = true
    new_contact.value = {organization: "", contacts: [], labels: []}
    loader.value = false
}

const search_organizations = (event) => {
    //contains filter organizations
    filtered_organizations.value = organizations.value.filter((organization) => {
        return organization.name.toLowerCase().includes(event.query.toLowerCase())
    })
}

const delete_document = (data) => {
    save_email.value.attachments.splice(data.index, 1)
    toast.add({severity: 'success', summary: 'Dokument wurde erfolgreich gelöscht', life: 3000})
}

const edit_documents = (event) => {
    if (event.data.filename !== event.newData.filename || event.data.classification !== event.newData.classification) {
        save_email.value.attachments[event.index].filename = event.newData.filename
        save_email.value.attachments[event.index].classification = event.newData.classification
        toast.add({severity: 'success', summary: 'Dokument wurde erfolgreich angepasst', life: 3000})
    }
}

const check_documents_completion = () => {
    let documents = save_email.value.attachments
    //check if all documents have a classification or if field classification exists
    let missing_documents = documents.filter(document => !document.classification || document.classification === '')
    console.log("missing_documents", missing_documents)
    return missing_documents.length !== 0
}

const delete_task = (data) => {
    save_email.value.tasks.splice(data.index, 1)
    toast.add({severity: 'success', summary: 'Aufgabe wurde erfolgreich gelöscht', life: 3000})
}

const edit_tasks = (event) => {
    console.log("task event", event)
    save_email.value.tasks[event.index].task = event.newData.task
    save_email.value.tasks[event.index].owner = event.newData.owner
    toast.add({severity: 'success', summary: 'Aufgabe wurde erfolgreich angepasst', life: 3000})
}

const reorder_tasks = (event) => {
    save_email.value.tasks = event.value
    toast.add({severity: 'success', summary: 'Aufgaben wurden erfolgreich neu sortiert', life: 3000})
}

const add_task = (data) => {
    save_email.value.tasks.push({task: '', owner: ''})
    toast.add({severity: 'success', summary: 'Neue Aufgabe wurde erfolgreich hinzugefügt', life: 3000})
}

const edit_contacts = (event) => {
    console.log("contact event", event)
    if (event.data.name !== event.newData.name || event.data.email !== event.newData.email || event.data.phone !== event.newData.phone) {
        new_contact.value.contacts[event.index].name = event.newData.name
        new_contact.value.contacts[event.index].email = event.newData.email
        new_contact.value.contacts[event.index].phone = event.newData.phone
        toast.add({severity: 'success', summary: 'Kontakt wurde erfolgreich angepasst', life: 3000})
    }
}

const add_contact = (data) => {
    new_contact.value.contacts.push({name: '', email: '', phone: ''})
    toast.add({severity: 'success', summary: 'Neuer Kontakt wurde erfolgreich hinzugefügt', life: 3000})
}

const delete_contact = (data) => {
    new_contact.value.contacts.splice(data.index, 1)
    toast.add({severity: 'success', summary: 'Kontakt wurde erfolgreich gelöscht', life: 3000})
}

const create_organization_and_contacts = () => {
    loader.value = true
    let payload = {
        organization: new_contact.value.organization,
        contacts: new_contact.value.contacts,
        attachments: save_email.value.attachments,
        tasks: save_email.value.tasks,
        email: save_email.value,
        sales_process: sales_process.value,
        sales_process_id: sales_process_id.value,
        sales_process_comment: sales_process_comment.value

    }
    if (sales_process.value) {
        payload.status = status.value
        payload.sales_process = true
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/create-pipedrive-organization-and-persons', payload)
    .then(response => {
        console.log(response.data)
        organization.value = response.data.organization_id
        loader.value = false
        save_email_slider.value = false
        toast.add({severity: 'success', summary: 'Firma und Kontakte wurden erfolgreich erstellt', life: 3000})
        get_emails()
    })
}

const delete_classification = (data) => {
    document_classifications.value.splice(data.index, 1)
    toast.add({severity: 'success', summary: 'Klassifikation wurde erfolgreich gelöscht', life: 3000})
}

const add_classification = () => {
    document_classifications.value.push({label: '', value: ''})
    toast.add({severity: 'success', summary: 'Neue Klassifikation wurde erfolgreich hinzugefügt', life: 3000})
}

const edit_classifications = (event) => {
    if (event.data.label !== event.newData.label || event.data.value !== event.newData.value) {
        document_classifications.value[event.index].label = event.newData.label
        document_classifications.value[event.index].value = event.newData.value
        toast.add({severity: 'success', summary: 'Klassifikation wurde erfolgreich angepasst', life: 3000})
    }
}

const reorder_classifications = (event) => {
    document_classifications.value = event.value
    toast.add({severity: 'success', summary: 'Klassifikationen wurden erfolgreich neu sortiert', life: 3000})
}

const get_user_name = (email) => {
    let user = users.value.find(user => user.email === email)
    return user ? user.name : ''
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}

const format_bytes_to_mb = (bytes) => {
    return (bytes / 1024 / 1024).toFixed(2) + ' MB'
}

const open_url = (url) => {
    window.open(url, '_blank')
}
</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <ConfirmDialog />
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText v-model="filter_emails['global'].value" placeholder="Suche" class="w-full" />
            </span>
            <Button label="Aktualisieren" icon="pi pi-refresh" class="p-button-success mr-2" @click="get_emails" />
        </template>
        <template #end>
            <Button v-if="!deleted_mode" icon="pi pi-trash" rounded class="p-button-text" @click="get_deleted_emails()" />
            <Button v-if="deleted_mode" icon="pi pi-inbox" rounded class="p-button-text" @click="get_inbox()" />
        </template>>
    </Toolbar>
    <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
        <DataTable v-model:filters="filter_emails" editMode="cell" @cell-edit-complete="edit_email" :value="emails" :rows="200" responsiveLayout="scroll"  :rowHover="true" :rowsPerPageOptions="[200,400,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="dc" header="Datum" sortable>
                <template #body="slotProps">
                    <Avatar icon="pi pi-envelope" shape="circle" class="bg-primary text-white mr-2" />
                    <span class="font-bold">{{ format_swiss_date_time(slotProps.data.dc) }}</span>
                </template>
            </Column>
            <Column field="email_from" header="Absender">
                <template #body="slotProps">
                    <span class="cursor-pointer hover:text-primary" @click="open_url('mailto:' + slotProps.data.email_from)">{{slotProps.data.email_from}}</span>
                </template>
            </Column>
            <Column field="email_subject" header="Betreff">
                <template #body="slotProps">
                    <span>{{slotProps.data.email_subject}}</span>
                </template>
            </Column>
            <Column field="email_body_text" header="Nachricht">
                <template #body="slotProps">
                    <Button class="p-button-rounded p-button-primary" icon="pi pi-eye" @click="view_email(slotProps.data)" />
                </template>
            </Column>
            <Column field="owner" header="Bearbeiter">
                <template #body="slotProps">
                    <Chip :label="get_user_name(slotProps.data.owner)" />
                </template>
                <template #editor="{ data, field }">
                    <Dropdown v-model="data[field]" :options="users" optionLabel="name" optionValue="email" showClear />
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Button v-if="!deleted_mode" class="p-button-rounded p-button-success mr-2" icon="pi pi-play" @click="process_email(slotProps.data)" v-tooltip.top="'E-Mail bearbeiten'" />
                    <Button v-if="!deleted_mode" class="p-button-rounded p-button-danger" icon="pi pi-trash" @click="delete_email(slotProps.data.workflow_id)" v-tooltip.top="'E-Mail löschen'" />
                    <Button v-if="deleted_mode" color="success" class="p-button-rounded" icon="pi pi-undo" @click="restore_email(slotProps.data.workflow_id)" v-tooltip.top="'E-Mail wiederherstellen'" />
                </template>
            </Column>
        </DataTable>
    </div>
    <!---------------------------------------------------------------->
    <!-------------------------- Dialogs ----------------------------->
    <!---------------------------------------------------------------->
    <!---------------------------------------------------------------->
    <!------------------------- View Email --------------------------->
    <!---------------------------------------------------------------->
    <Sidebar header="E-Mail" v-model:visible="view_email_slider" position="left" style="width: 70%">
        <div class="grid">
            <div class="col-12 mt-4">
                <div class="font-bold p2">E-Mail Meta</div>
            </div>
            <div class="col-6 mt-4">
                <span class="p-float-label">
                    <InputText id="email_from" v-model="email.email_from" class="w-full" />
                    <label for="email_from">Absender</label>
                </span>
            </div>
            <div class="col-6 mt-4">
                <span class="p-float-label">
                    <InputText id="dc" v-model="email.formatted_dc" class="w-full" />
                    <label for="dc">Datum</label>
                </span>
            </div>
            <div class="col-12 mt-4">
                <span class="p-float-label">
                    <InputText id="subject" v-model="email.email_subject" class="w-full" />
                    <label for="subject">Betreff</label>
                </span>
            </div>
            <div class="col-12 mt-2">
                <div class="font-bold p2">Inhalt</div>
            </div>
            <div class="col-12 mt-2">
                <Textarea id="body" v-model="email.email_body_text" class="w-full" style="height: 400px" />
            </div>
            <div class="col-12 mt-2" v-if="email.attachments">
                <div class="font-bold p2">Attachments</div>
            </div>
            <div class="col-12 mt-1" v-if="email.attachments">
                <div class="p-d-flex p-flex-wrap">
                    <div v-for="(attachment, key) in email.attachments" :key="key" class="flex flex-wrap align-items-center mr-2 mt-2">
                        <Avatar icon="pi pi-paperclip" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold mr-2 cursor-pointer" @click="open_url(attachment.url)">{{attachment.filename}}</span>
                        <span>({{format_bytes_to_mb(attachment.size)}})</span>
                    </div>
                </div>
            </div>
        </div>
    </Sidebar>
    <Sidebar header="E-Mail ablegen" v-model:visible="save_email_slider" position="right" style="width: 70%">
        <TabView class="w-full mt-4">
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-folder" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">E-Mail ablegen</span>
                    </div>
                </template>
                <div class="grid">
                    <div class="col-12 mt-4">
                        <span class="p-float-label">
                            <AutoComplete id="organization" v-model="organization" :suggestions="filtered_organizations" optionLabel="name" @complete="search_organizations" forceSelection dropdown class="w-full" /> 
                            <label for="organization">Firma auswählen</label>
                        </span>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="flex align-items-center">
                            <Button icon="pi pi-eye" class="p-button-rounded mr-3" label="E-Mail ansehen" @click="view_email(save_email)" />
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="flex align-items-center">
                            <Checkbox v-model="sales_process" inputId="sales_process" binary />
                            <label for="sales_process" class="ml-2 white-space-nowrap"> Verkaufsprozess starten </label>
                            <Dropdown v-model="sales_process_id" :disabled="sales_process == false ? true : false" :options="statuses" showClear optionLabel="name" optionValue="id" class="ml-3 w-full" placeholder="Schritt auswählen" />
                        </div>
                    </div>
                    <div class="col-12 mt-3" v-if="sales_process_id">
                        <div class="font-bold">Bemerkung</div>
                        <Editor v-model="sales_process_comment" class="w-full mt-2" editorStyle="height: 150px">
                            <template v-slot:toolbar>
                                <span class="ql-formats">
                                    <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                    <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                    <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                    <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                    <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                    <button class="ql-image" v-tooltip.bottom="'Bild hochladen'"></button>
                                </span>
                            </template>
                        </Editor>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="font-bold p2">Dokumente ablegen</div>
                    </div>
                    <div class="col-12 mt-2">
                        <div rounded class="overflow-hidden shadow-4 rounded border-200">
                            <DataTable editMode="cell" @cell-edit-complete="edit_documents" :value="save_email.attachments" responsiveLayout="scroll" :rowHover="true">
                                <Column field="filename" header="Dateiname" style="width: 50%" class="overflow-hidden">
                                    <template #editor="slotProps">
                                        <InputText v-model="slotProps.data.filename" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <InputText v-model="slotProps.data.filename" class="w-full" />
                                    </template>
                                </Column>
                                <Column field="classification" header="Typ" style="width: 30%">
                                    <template #editor="slotProps">
                                        <Dropdown v-model="slotProps.data.classification" :options="document_classifications" optionLabel="label" optionValue="value" />
                                    </template>
                                    <template #body="slotProps">
                                        <Chip v-if="slotProps.data.classification" :label="slotProps.data.classification" />
                                        <span v-if="!slotProps.data.classification" class="text-red-600">Dokumententyp auswählen</span>
                                    </template>
                                </Column>
                                <Column style="width: 20%">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-eye" class="p-button-text p-button-rounded mr-2" @click="open_url(slotProps.data.url)" />
                                        <Button icon="pi pi-trash" class="p-button-text p-button-rounded p-button-danger" @click="delete_document(slotProps)" />
                                    </template>
                                    <template #header="slotProps">
                                        <Button icon="pi pi-upload" class="p-button-text p-button-rounded" @click="open_url(slotProps.data.url)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <div class="col-12 mt-3" hidden>
                        <div class="font-bold p2">Aufgaben erstellen</div>
                    </div>
                    <div class="col-12 mt-2" hidden>
                        <div rounded class="overflow-hidden shadow-4 rounded border-200">
                            <DataTable editMode="cell" @cell-edit-complete="edit_tasks" :value="save_email.tasks" responsiveLayout="scroll" :rowHover="true"  @rowReorder="reorder_tasks">
                                <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                                <Column field="task" header="Augabe" style="width: 50%" class="overflow-hidden">
                                    <template #editor="slotProps">
                                        <InputText v-model="slotProps.data.task" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <InputText v-model="slotProps.data.task" class="w-full" />
                                    </template>
                                </Column>
                                <Column field="owner" header="Bearbeiter" style="width: 30%">
                                    <template #editor="slotProps">
                                        <Dropdown v-model="slotProps.data.owner" :options="users" optionLabel="name" optionValue="email" />
                                    </template>
                                    <template #body="slotProps">
                                        <Chip :label="get_user_name(slotProps.data.owner)" />
                                    </template>
                                </Column>
                                <Column>
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" class="p-button-text p-button-rounded p-button-danger" @click="delete_task(slotProps)" />
                                    </template>
                                    <template #header="slotProps">
                                        <Button icon="pi pi-plus" class="p-button-text p-button-rounded" @click="add_task(slotProps)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <div class="p-3">
                            <div class="text-red-600" v-if="!organization">Bitte eine Firma auswählen</div>
                            <div class="text-red-600" v-if="check_documents_completion()">Bitte alle Dokumente klassifizieren.</div>
                        </div>
                        <Button :disabled="check_documents_completion() ? true : false" label="E-Mail ablegen" icon="pi pi-save" class="mr-2 w-auto p-button-success" />
                        <Button label="Abbrechen" icon="pi pi-times" class="p-button-danger w-auto" @click="save_email_slider = false" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-user-plus" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Neuen Kontakt erstellen</span>
                    </div>
                </template>
                <div class="grid">
                    <div class="col-6 mt-5">
                        <span class="p-float-label">
                            <InputText id="name" v-model="new_contact.organization" class="w-full" />
                            <label for="name">Firmenname</label>
                        </span>
                    </div>
                    <div class="col-6 mt-5">
                        <span class="p-float-label">
                            <MultiSelect id="company_type" v-model="new_contact.labels" :options="company_types" optionLabel="name" optionValue="value" class="w-full" />
                            <label for="company_type">Firmentyp</label>
                        </span>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="flex align-items-center">
                            <Button icon="pi pi-eye" class="p-button-rounded mr-3" label="E-Mail ansehen" @click="view_email(save_email)" />
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="flex align-items-center">
                            <Checkbox v-model="sales_process" inputId="sales_process" binary />
                            <label for="sales_process" class="ml-2 white-space-nowrap"> Verkaufsprozess starten </label>
                            <Dropdown v-model="sales_process_id" :disabled="sales_process == false ? true : false" :options="statuses" showClear optionLabel="name" optionValue="id" class="ml-3 w-full" placeholder="Schritt auswählen" />
                        </div>
                    </div>
                    <div class="col-12 mt-3" v-if="sales_process_id">
                        <div class="font-bold">Bemerkung</div>
                        <Editor v-model="sales_process_comment" class="w-full mt-2" editorStyle="height: 150px">
                            <template v-slot:toolbar>
                                <span class="ql-formats">
                                    <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                    <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                    <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                    <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                    <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                    <button class="ql-image" v-tooltip.bottom="'Bild hochladen'"></button>
                                </span>
                            </template>
                        </Editor>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="font-bold p2">Kontaktpersonen</div>
                    </div>
                    <div class="col-12 mt-2">
                        <div rounded class="overflow-hidden shadow-4 rounded border-200">
                            <DataTable editMode="cell" @cell-edit-complete="edit_contacts" :value="new_contact.contacts" responsiveLayout="scroll" :rowHover="true">
                                <Column field="name" header="Name" style="width: 30%" class="overflow-hidden">
                                    <template #editor="slotProps">
                                        <InputText v-model="slotProps.data.name" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <InputText v-model="slotProps.data.name" class="w-full" />
                                    </template>
                                </Column>
                                <Column field="email" header="Email" style="width: 30%">
                                    <template #editor="slotProps">
                                        <InputText v-model="slotProps.data.email" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <span v-if="slotProps.data.email">{{ slotProps.data.email }}</span>
                                        <span v-if="!slotProps.data.email" class="text-orange-600">Email eingeben (optional)</span>
                                    </template>
                                </Column>
                                <Column field="phone" header="Telefon" style="width: 30%">
                                    <template #editor="slotProps">
                                        <InputText v-model="slotProps.data.phone" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <span v-if="slotProps.data.phone">{{ slotProps.data.phone }}</span>
                                        <span v-if="!slotProps.data.phone" class="text-orange-600">Telefon eingeben (optional)</span>
                                    </template>
                                </Column>
                                <Column>
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" class="p-button-text p-button-rounded p-button-danger" @click="delete_contact(slotProps)" />
                                    </template>
                                    <template #header="slotProps">
                                        <Button icon="pi pi-plus" class="p-button-text p-button-rounded" @click="add_contact(slotProps)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="font-bold p2">Dokumente ablegen</div>
                    </div>
                    <div class="col-12 mt-2">
                        <div rounded class="overflow-hidden shadow-4 rounded border-200">
                            <DataTable editMode="cell" @cell-edit-complete="edit_documents" :value="save_email.attachments" responsiveLayout="scroll" :rowHover="true">
                                <Column field="filename" header="Dateiname" style="width: 50%" class="overflow-hidden">
                                    <template #editor="slotProps">
                                        <InputText v-model="slotProps.data.filename" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <InputText v-model="slotProps.data.filename" class="w-full" />
                                    </template>
                                </Column>
                                <Column field="classification" header="Typ" style="width: 30%">
                                    <template #editor="slotProps">
                                        <Dropdown v-model="slotProps.data.classification" :options="document_classifications" optionLabel="label" optionValue="value" />
                                    </template>
                                    <template #body="slotProps">
                                        <Chip :label="slotProps.data.classification" />
                                    </template>
                                </Column>
                                <Column style="width: 20%">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-eye" class="p-button-text p-button-rounded mr-2" @click="open_url(slotProps.data.url)" />
                                        <Button icon="pi pi-trash" class="p-button-text p-button-rounded p-button-danger" @click="delete_document(slotProps)" />
                                    </template>
                                    <template #header="slotProps">
                                        <Button icon="pi pi-upload" class="p-button-text p-button-rounded" @click="open_url(slotProps.data.url)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <div class="col-12 mt-3" hidden>
                        <div class="font-bold p2">Aufgaben erstellen</div>
                    </div>
                    <div class="col-12 mt-2" hidden>
                        <div rounded class="overflow-hidden shadow-4 rounded border-200">
                            <DataTable editMode="cell" @cell-edit-complete="edit_tasks" :value="save_email.tasks" responsiveLayout="scroll" :rowHover="true"  @rowReorder="reorder_tasks">
                                <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                                <Column field="task" header="Augabe" style="width: 50%" class="overflow-hidden">
                                    <template #editor="slotProps">
                                        <InputText v-model="slotProps.data.task" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <InputText v-model="slotProps.data.task" class="w-full" />
                                    </template>
                                </Column>
                                <Column field="owner" header="Bearbeiter" style="width: 30%">
                                    <template #editor="slotProps">
                                        <Dropdown v-model="slotProps.data.owner" :options="users" optionLabel="name" optionValue="email" />
                                    </template>
                                    <template #body="slotProps">
                                        <Chip :label="get_user_name(slotProps.data.owner)" />
                                    </template>
                                </Column>
                                <Column>
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" class="p-button-text p-button-rounded p-button-danger" @click="delete_task(slotProps)" />
                                    </template>
                                    <template #header="slotProps">
                                        <Button icon="pi pi-plus" class="p-button-text p-button-rounded" @click="add_task(slotProps)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <Button label="Kontakt erstellen & E-Mail ablegen" icon="pi pi-save" class="mr-2 w-auto p-button-success" @click="create_organization_and_contacts()" />
                        <Button label="Abbrechen" icon="pi pi-times" class="p-button-danger w-auto" @click="save_email_slider = false" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-cog" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Dokumententypen</span>
                    </div>
                </template>
                <div rounded class="overflow-hidden shadow-4 rounded border-200">
                    <DataTable editMode="cell" @cell-edit-complete="edit_classifications" :value="document_classifications" responsiveLayout="scroll" :rowHover="true"  @rowReorder="reorder_classifications">
                        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                        <Column field="label" header="ID" style="width: 50%" class="overflow-hidden">
                            <template #editor="slotProps">
                                <InputText v-model="slotProps.data.label" class="w-full" />
                            </template>
                            <template #body="slotProps">
                                <span v-if="slotProps.data.label">{{slotProps.data.value}}</span>
                            </template>
                        </Column>
                        <Column field="value" header="Dokumententyp" style="width: 50%" class="overflow-hidden">
                            <template #editor="slotProps">
                                <InputText v-model="slotProps.data.value" class="w-full" />
                            </template>
                            <template #body="slotProps">
                                <span v-if="slotProps.data.value">{{slotProps.data.value}}</span>
                            </template>
                        </Column>
                        <Column>
                            <template #body="slotProps">
                                <Button icon="pi pi-trash" class="p-button-text p-button-rounded p-button-danger" @click="delete_classification(slotProps)" />
                            </template>
                            <template #header="slotProps">
                                <Button icon="pi pi-plus" class="p-button-text p-button-rounded" @click="add_classification(slotProps)" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="mt-3">
                    <Button label="Speichern" icon="pi pi-save" class="mr-2 w-auto p-button-success" @click="update_classifications" />
                </div>
            </TabPanel>
        </TabView>
    </Sidebar>
</template>
    