<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import { FilterMatchMode } from 'primevue/api'
import { useRouter } from 'vue-router'
import axios from 'axios'

import Company from './sub_components/Prosperita-Company.vue'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const router = useRouter()

// filters
const filter_contacts = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                            'labels': {value: null, matchMode: FilterMatchMode.CONTAINS},
                            'warnings': {value: [], matchMode: FilterMatchMode.CONTAINS}})

// data
const companies = ref([])
const contact = ref(null)
const statuses = ref([])
const warnings = ref([])
const company_id = ref(null)
const contact_slider = ref(false)
const company_slider = ref(false)
const company_slider_position = 'right'
const contact_type = ref(null)
const counts = ref({})

onMounted( async () => {
    get_companies()
    get_crm_statuses()
})

const types = ref([])
const get_companies = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-companies')
    .then(response => {
        contact_type.value = 1
        console.log(response.data)
        companies.value = response.data.data
        types.value = response.data.types
        warnings.value = response.data.warnings
        loader.value = false
    })
}

const company_types = ref([
    {name: 'Kunde', value: 10},
    {name: 'Broker', value: 28},
    {name: 'Interessent', value: 11},
    {name: 'Partner', value: 12},
    {name: 'Keine Kategorie', value: 13}
])

const save_contact = () => {
    console.log('save contact')
    toast.add({severity:'success', summary:'Kontakt gespeichert', life: 3000})
    edit_contact.value.name = false
    edit_contact.value.email = false
    edit_contact.value.phone = false
    edit_contact.value.street = false
    edit_contact.value.postal_code = false
    edit_contact.value.city = false
    edit_contact.value.labels = false
    edit_contact.value.broker = false
    edit_contact.value.deal = false
    edit_contact.value.legal_form = false
    edit_contact.value.employee_count = false
}

const get_crm_statuses = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-crm-statuses')
    .then(response => {
        statuses.value = response.data
        console.log("statuses", response.data)
        loader.value = false
    })
}

const new_deal = () => {
    console.log('new deal')
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/create-contact-deal', contact.value)
    .then(response => {
        console.log(response.data)
        toast.add({severity:'success', summary:'Vertriebsprozess gestartet', life: 3000})
        get_contacts()
    })
}

const company_details = (event) => {
    contact.value = event.data
    if (contact_type.value == 1) {
        company_id.value = event.data.id
        company_slider.value = true
    }
    if (contact_type.value == 2) {
        contact_slider.value = true
    }
}

const filter_type = (type) => {
    filter_contacts.value.labels.value = [type]
    console.log(filter_contacts.value)
}

const filter_warning = (warning) => {
    filter_contacts.value.warnings.value = [warning]
    console.log(filter_contacts.value)
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const show_warnings = ref(false)
const selected_type = ref("Alle Firmen")
const expanded_companies = ref([])

const search_update = () => {
    if(filter_contacts.value['global'].value.length > 0){
        expanded_companies.value = companies.value.reduce((com, p) => (com[p.id] = true) && com, {})
        filter_contacts.value['labels'].value = []
        filter_contacts.value['warnings'].value = []
    }
    else{
        expanded_companies.value = null
    }
}

const company = ref({
    name: null,
    labels: []
})
const create_company_dialog = ref(false)
const new_company = () => {
    create_company_dialog.value = true
}

const save_company = () => {
    console.log(company.value)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/create-company', company.value)
    .then(response => {
        console.log(response.data)
        toast.add({severity:'success', summary:'Firma gespeichert', life: 3000})
        create_company_dialog.value = false
        get_companies()
        filter_contacts.value['labels'].value = []
        filter_contacts.value['warnings'].value = []
        filter_contacts.value['global'].value = company.value.name
    })
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <ConfirmDialog />
    <Toolbar>
        <template #start>
            <Button  v-for="type in types" :key="type" :class="selected_type == type.name ? 'bg-primary' : ''" rounded :label="type.name" class="mr-2 bg-black-alpha-90 border-black-alpha-70 hover:border-primary hover:bg-primary" @click="filter_type(type.id), selected_type = type.name" :badge="String(type.total)" badgeSeverity="contrast" />
        </template>
        <template #end>
            <Button label="Export" icon="pi pi-file-excel" v-tooltip.top="'Exportieren'" class="mr-2" />
        </template>
    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left mr-3">
                <i class="pi pi-search" />
                <InputText v-model="filter_contacts['global'].value" placeholder="Suche" class="w-full" @update:modelValue="search_update()" />
            </span>
            <span v-if="!show_warnings" class="text-red-700 cursor-pointer" @click="show_warnings = true">
                Fehler anzeigen
            </span>
            <span v-if="show_warnings">
                <Button v-for="warning in warnings" @click="filter_warning(warning.id)" :key="warning" icon="pi pi-exclamation-triangle" rounded class="mr-2 border-red-700 hover:bg-primary hover:border-primary bg-red-700 text-white pt-1 pb-1 pl-3 pr-3" :label="warning.name" v-tooltip.top="'Fehlerhafte Firmen anzeigen'" :badge="String(warning.total)" badgeSeverity="danger" />
                <span class="cursor-pointer" @click="show_warnings = false">
                    Fehler ausblenden
                </span>
            </span>
        </template>
        <template #end>
            <Button label="Neue Firma" icon="pi pi-plus" @click="new_company()" class="w-auto mr-2" v-tooltip.bottom="'Neue Firma erfassen'" />
        </template>
    </Toolbar>
    <div rounded class="overflow-hidden shadow-3 border-200 mt-4">
        <DataTable v-model:filters="filter_contacts" v-model:expandedRows="expanded_companies" dataKey="id" @row-click="company_details" :value="companies" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,500,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} to {last} from {totalRecords}">
            <Column field="name" header="Name">
                <template #body="slotProps">
                    <Avatar icon="pi pi-building" shape="circle" class="mr-2 bg-primary text-white" />
                    <span class="font-bold mr-2">{{slotProps.data.name}}</span>
                    <i v-if="slotProps.data.email" class="pi pi-envelope mr-2 pointer-cursor text-primary" />
                    <i v-if="slotProps.data.phone" class="pi pi-phone mr-2 pointer-cursor text-primary" />
                </template>
            </Column>
            <Column header="Typ" sortable>
                <template #body="slotProps">
                    <template v-for="label in slotProps.data.labels" :key="label">
                        <Chip v-if="label == 10" class="mr-2 mb-1 bg-blue-700 text-white" label="Kunde" />
                        <Chip v-if="label == 11" class="mr-2 mb-1 bg-red-700 text-white" label="Interessent" />
                        <Chip v-if="label == 28" class="mr-2 mb-1 bg-orange-700 text-white" label="Broker" />
                    </template>
                </template>
            </Column>
            <Column field="contacts" header="Kontakte" sortable>
                <template #body="slotProps">
                    <Chip icon="pi pi-user" class="mr-2 mb-1" :label="String(slotProps.data.contacts)" v-tooltip.top="'Anzahl verknüpfte Kontakte'" />
                </template>
            </Column>
            <Column field="deal" header="Vetrieb" sortable>
                <template #body="slotProps">
                    <Chip v-if="slotProps.data.deal" icon="pi pi-folder" :label="slotProps.data.deal" class="mr-2 bg-green-200 text-black-alpha-90" />
                </template>
            </Column>
            <Column field="broker" header="co" sortable>
                <template #body="slotProps">
                    <Chip v-if="slotProps.data.broker" icon="pi pi-lock" class="mr-2 mb-1 bg-red-700 text-white" :label="slotProps.data.broker" />
                </template>
            </Column>
            <Column field="warnings" hidden />
            <Column field="phone" header="Telefon" hidden />
            <Column field="email" header="E-Mail" hidden />
            <template #expansion="slotProps">
                <div v-if="slotProps.data.people.length > 0" rounded class="overflow-hidden shadow-3 border-200 mt-1">
                    <DataTable :value="slotProps.data.people">
                        <Column field="name" header="Name">
                            <template #body="slotProps">
                                <div class="flex align-items-center">
                                    <Avatar icon="pi pi-user" shape="circle" class="mr-2 bg-primary text-white" />
                                    <span class="font-bold mr-2">{{slotProps.data.name}}</span>
                                </div>
                            </template>
                        </Column>
                        <Column field="email" header="E-Mail">
                            <template #body="slotProps">
                                <div class="flex align-items-center">
                                    <span class="mr-2">{{slotProps.data.email}}</span>
                                    <i v-if="slotProps.data.email" class="pi pi-envelope mr-2 pointer-cursor text-primary" @click="open_url('mailto:'+slotProps.data.email)" />
                                </div>
                            </template>
                        </Column>
                        <Column field="phone" header="Telefon">
                            <template #body="slotProps">
                                <div class="flex align-items-center">
                                    <span class="mr-2">{{slotProps.data.phone}}</span>
                                    <i v-if="slotProps.data.phone" class="pi pi-phone mr-2 pointer-cursor text-primary" @click="open_url('tel:'+slotProps.data.phone)" />
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template>
        </DataTable>
    </div>
    <!----------------------------------------------------------------->
    <!-- Contact Slider -->
    <!----------------------------------------------------------------->
    <Sidebar v-model:visible.sync="contact_slider" position="right" style="width: 50%">
        <TabView class="w-full mt-4">
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-user" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Kontakt</span>
                    </div>
                </template>
                <div class="col-12 grid">
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Namen
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_contact.name" v-model="contact.first_name" class="w-full" placeholder="Vorname" />
                        <InputText v-if="edit_contact.name" v-model="contact.last_name" class="w-full" placeholder="Nachname" />
                        <span v-else class="mr-2">{{contact.name}}</span>
                        <i v-if="!edit_contact.name" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_contact.name = true" />
                        <i v-if="edit_contact.name" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_contact()" />
                        <i v-if="edit_contact.name" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_contact.name = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        E-Mail
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_contact.email" v-model="contact.email" class="w-full" />
                        <i v-if="contact.email && !edit_contact.email" class="pi pi-envelope mr-2 cursor-pointer text-primary hover:text-black-alpha-90" @click="open_url('mailto:'+contact.email)" />
                        <span v-if="!edit_contact.email" class="mr-2">{{contact.email}}</span>
                        <i v-if="!edit_contact.email" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_contact.email = true" />
                        <i v-if="edit_contact.email" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_contact()" />
                        <i v-if="edit_contact.email" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_contact.email = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Telefon
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_contact.phone" v-model="contact.phone" class="w-full" />
                        <i v-if="contact.phone && !edit_contact.phone" class="pi pi-phone mr-2 cursor-pointer text-primary hover:text-black-alpha-90" @click="open_url('tel:'+contact.phone)" />
                        <span v-if="!edit_contact.phone" class="mr-2 cursor-pointer text-primary hover:text-black-alpha-90" @click="open_url('tel:'+contact.phone)">{{contact.phone}}</span>
                        <i v-if="!edit_contact.phone" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_contact.phone = true" />
                        <i v-if="edit_contact.phone" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_contact()" />
                        <i v-if="edit_contact.phone" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_contact.phone = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Firma
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Chip icon="pi pi-building" class="mr-2" :label="contact.company" />
                        <i class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Typ
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span v-if="!edit_contact.labels">
                            <template v-for="label in contact.labels" :key="label">
                                <Chip v-if="label == 10" class="mr-2 mb-1 bg-blue-700 text-white" label="Firma" />
                                <Chip v-if="label == 28" class="mr-2 mb-1 bg-orange-700 text-white" label="Broker" />
                            </template>
                        </span>
                        <i v-if="!edit_contact.labels" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_contact.labels = true" />
                        <MultiSelect v-if="edit_contact.labels" v-model="contact.labels" :options="types" optionLabel="name" optionValue="value" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Broker
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Chip v-if="contact.broker" icon="pi pi-lock" class="mr-2 mb-1 bg-red-700 text-white" :label="contact.broker" />
                        <i class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" />
                    </div>
                    <div v-if="!contact.deal" class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Vertriebsprozess starten
                    </div>
                    <div v-if="!contact.deal" class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Dropdown v-model="contact.deal" :options="statuses" showClear optionLabel="name" optionValue="id" class="mr-2" />
                        <Button :disabled="contact.deal ? false : true" icon="pi pi-play" @click="new_deal()" class="bg-green-700 text-white border-green-600" v-tooltip.bottom="'Neuer Vertriebsprozess starten'" />
                    </div>
                    <div v-if="contact.deal" class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Vertriebsprozess
                    </div>
                    <div v-if="contact.deal" class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Chip icon="pi pi-folder" :label="contact.deal" class="mr-2 bg-green-200 text-black-alpha-90 cursor-pointer" @click="router.push('/dashboard/Vertrieb?deal_id='+contact.deal_id)" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-history" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Verlauf</span>
                        <Badge value="0" class="ml-2 bg-gray-100 text-black-alpha-90" />
                    </div>
                </template>
                Coming soon.
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-file" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Dokumente</span>
                        <Badge value="0" class="ml-2 bg-gray-100 text-black-alpha-90" />
                    </div>
                </template>
                Coming soon.
            </TabPanel>
        </TabView>
    </Sidebar>

    <Sidebar v-model:visible.sync="company_slider" :position="company_slider_position" style="width: 50%">
            <Company v-if="company_id" :company_id="company_id" />
    </Sidebar>

    <Dialog v-model:visible="create_company_dialog" :style="{width: '70%'}" header="Neue Firma" :modal="true" class="p-fluid z-5">
        <div class="grid">
            <div class="col-6 mt-5">
                <span class="p-float-label">
                    <InputText id="company_name" v-model="company.name" />
                    <label for="company_name">Firmenname</label>
                </span>
            </div>
            <div class="col-6 mt-5">
                <span class="p-float-label">
                    <MultiSelect id="company_type" v-model="company.labels" :options="company_types" optionLabel="name" optionValue="value" class="w-full" />
                    <label for="company_type">Firmentyp</label>
                </span>
            </div>
            <div class="col-12 mt-3">
                <Button label="Speichern" icon="pi pi-check" @click="save_company()" class="bg-green-700 text-white border-green-600 w-auto mr-2" />
                <Button label="Abbrechen" icon="pi pi-times" @click="create_company_dialog = false" class="bg-red-700 text-white border-red-600 w-auto" />
            </div>
        </div>
    </Dialog>
</template>